// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

export default function useCalendar() {
    // Use toast
    const toast = useToast()
    // ------------------------------------------------
    // refCalendar
    // ------------------------------------------------
    const refCalendar = ref(null)

    // ------------------------------------------------
    // calendarApi
    // ------------------------------------------------
    let calendarApi = null
    onMounted(() => {
        calendarApi = refCalendar.value.getApi()
    })

    // ------------------------------------------------
    // event
    // ------------------------------------------------
    const blankEvent = {
        start: '',
        end: '',
        allDay: false,
    }
    const event = ref(JSON.parse(JSON.stringify(blankEvent)))
    const clearEventData = () => {
        event.value = JSON.parse(JSON.stringify(blankEvent))
    }

    const isEventHandlerSidebarActive = ref(false)
    const isCalendarOverlaySidebarActive = ref(false)

    const displayToast = (title, icon, state) => {
        toast({
            component: ToastificationContent,
            props: {
                title,
                position: 'bottom-right',
                icon,
                variant: state,
            },
        })
    }

    // ------------------------------------------------
    // grabEventDataFromEventApi
    // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
    // ! You need to update below function as per your extendedProps
    // ------------------------------------------------
    const grabEventDataFromEventApi = eventApi => {
        const id = parseInt(eventApi.id, 10)
        const {
            start,
            end,
            allDay,
        } = eventApi
        const { userId } = store.getters['calendar/getEvent'](id)
        return {
            id,
            start,
            end,
            allDay,
            userId,
        }
    }

    // ------------------------------------------------
    // addEvent
    // ------------------------------------------------
  const addEvent = eventData => {
        store.dispatch('calendar/addEvent', eventData)
            .then(() => {
                displayToast('Evénement ajouté', 'CheckIcon', 'success')
            })
    }

    // ------------------------------------------------
    // updateEvent
    // ------------------------------------------------
    const updateEvent = eventData => store.dispatch('calendar/updateEvent', eventData)
        .then(() => {
            displayToast('Evénement modifié', 'CheckIcon', 'success')
            return true
        })
        .catch(() => false)
    // ------------------------------------------------
    // removeEvent
    // ------------------------------------------------
    const removeEvent = () => {
        if (event.value.start < new Date() && new Date() < event.value.end) {
            displayToast('L\'événement a déjà commencé', 'XIcon', 'danger')
        } else if (event.value.end < new Date()) {
            displayToast('L\'événement est antérieur à la date du jour', 'XIcon', 'danger')
        } else {
            store.dispatch('calendar/removeEvent', parseInt(event.value.id, 10)).then(() => {
                displayToast('Evénement supprimé', 'TrashIcon', 'success')
            })
        }
    }

    // ------------------------------------------------
    // refetchEvents
    // ------------------------------------------------
    const refetchEvents = () => {
        calendarApi.refetchEvents()
    }

    const events = computed(() => store.getters['calendar/events'])

    const fetchEvents = (start, end) => store.dispatch('calendar/fetchEvents', { start, end })

    const fetchUsersAvailable = () => store.dispatch('calendar/fetchUsersAvailable')

    // ------------------------------------------------------------------------
    // calendarOptions
    // * This isn't considered in UI because this is the core of calendar app
    // ------------------------------------------------------------------------
  const calendarOptions = ref({
        longPressDelay: 0,
        timeZone: 'local',
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        allDaySlot: false, // Disabled until full support
        events,
        headerToolbar: {
            start: 'sidebarToggle,prev,next,title',
            end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        height: 'auto',
        initialView: 'timeGridWeek',
        viewDidMount(view) {
          fetchEvents(view.view.activeStart, view.view.activeEnd)
          fetchUsersAvailable()
        },
        selectable: true,
        droppable: false,
        eventOverlap: (stillEvent, movingEvent) => stillEvent && movingEvent?.extendedProps?.type === 'intervenant',
        forceEventDuration: true,
      eventReceive: info => {
          if (info.event.start < new Date()) {
            displayToast('La date de création est antérieure à la date du jour', 'XIcon', 'danger')
          }
          const draggedElUserId = info.draggedEl.getAttribute('data-user-id')
            info.event.remove()
            const draggedElStart = info.event.start.toISOString()
            const draggedElEnd = info.event.end.toISOString()
            const eventToChange = calendarApi.getEvents().find(stillEvent => {
                const startStillEvent = stillEvent.start.toISOString()
                const endStillEvent = stillEvent.end.toISOString()
                const isStartInEvent = startStillEvent <= draggedElStart && draggedElStart < endStillEvent
                const isEndInEvent = startStillEvent < draggedElEnd && draggedElEnd <= endStillEvent
                return (isStartInEvent && isEndInEvent) || (isStartInEvent || isEndInEvent)
            })
            if (eventToChange) {
                if (eventToChange.end < Date.now()) {
                    displayToast('L\'événement est antérieur à la date du jour', 'XIcon', 'danger')
                } else if (eventToChange.extendedProps.userId === draggedElUserId) {
                    displayToast(`L'intervenant de cet événément est déjà ${info.event.title}`, 'XIcon', 'danger')
                } else {
                  displayToast('Un intervenant est déjà affecté à cette plage horaire', 'XIcon', 'danger')
                    // const eventToSend = grabEventDataFromEventApi(eventToChange)
                    // eventToSend.userId = draggedElUserId
                    // updateEvent(eventToSend)
                }
            } else {
                addEvent({
                    allDay: info.event.allDay,
                    start: info.event.startStr,
                    userId: draggedElUserId,
                    end: info.event.endStr,
                })
            }
        },
        customButtons: {
            prev: { // this overrides the prev button
                text: 'Prev',
                click: () => {
                    calendarApi.prev()
                    fetchEvents(calendarApi.view.activeStart, calendarApi.view.activeEnd)
                },
            },
            next: { // this overrides the next button
                text: 'Next',
                click: () => {
                    calendarApi.next()
                    fetchEvents(calendarApi.view.activeStart, calendarApi.view.activeEnd)
                },
            },
            sidebarToggle: {
                // --- This dummy text actual icon rendering is handled using SCSS ----- //
                text: 'sidebar',
                click() {
                    // eslint-disable-next-line no-use-before-define
                    isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
                },
            },
        },
        locales: [frLocale],
        /*
          Enable dragging and resizing event
          ? Docs: https://fullcalendar.io/docs/editable
        */
        editable: true,

        /*
          Enable resizing event from start
          ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
        */
        eventResizableFromStart: true,

        /*
          Automatically scroll the scroll-containers during event drag-and-drop and date selecting
          ? Docs: https://fullcalendar.io/docs/dragScroll
        */
        dragScroll: true,

        /*
          Max number of events within a given day
          ? Docs: https://fullcalendar.io/docs/dayMaxEvents
        */
        dayMaxEvents: 1,

        /*
          Determines if day names and week names are clickable
          ? Docs: https://fullcalendar.io/docs/navLinks
        */
        navLinks: true,

        eventClick(info) {
            // * Only grab required field otherwise it goes in infinity loop
            // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
            event.value = grabEventDataFromEventApi(info.event)
            // eslint-disable-next-line no-use-before-define
            isEventHandlerSidebarActive.value = true
        },

        select(info) {
            if (info.start > new Date()
                && events.value.filter(e => e.startDate < info.end && info.start < e.endDate).length === 0) {
                event.value = {
                    start: info.startStr,
                    end: info.endStr,
                }
                isEventHandlerSidebarActive.value = true
            } else if (events.value.filter(e => e.startDate < info.end && info.start < e.endDate).length === 0) {
                displayToast('La date de création est antérieure à la date du jour', 'XIcon', 'danger')
            } else {
                displayToast('Un événement se trouve déjà sur cette plage horaire', 'XIcon', 'danger')
            }
        },

        /*
          Handle event drop (Also include dragged event)
          ? Docs: https://fullcalendar.io/docs/eventDrop
          ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
        */
      eventDrop(info) {
            updateEvent(grabEventDataFromEventApi(info.event))
                .then(updated => {
                    if (!updated) {
                        info.revert()
                    }
                })
        },

        /*
          Handle event resize
          ? Docs: https://fullcalendar.io/docs/eventResize
        */
    eventResize(info) {
        updateEvent(grabEventDataFromEventApi(info.event))
          .then(updated => {
            if (!updated) {
              info.revert()
              displayToast('La date de l\'évenement est antérieure à la date du jour', 'XIcon', 'danger')
            }
          })
      },
      rerenderDelay: 350,
    })

    // ------------------------------------------------------------------------

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*
    return {
        refCalendar,
        calendarOptions,
        event,
        events,
        clearEventData,
        addEvent,
        updateEvent,
        removeEvent,
        refetchEvents,
        fetchEvents,

        // ----- UI ----- //
        isEventHandlerSidebarActive,
        isCalendarOverlaySidebarActive,
    }
}

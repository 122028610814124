<template>
  <div class="app-calendar border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div class="col app-calendar-sidebar flex-grow-0 d-flex flex-column"
           :class="{ 'show': isCalendarOverlaySidebarActive }"
      >
        <calendar-sidebar
          ref="refSidebar"
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
          @sidebar-active="isCalendarOverlaySidebarActive = false"
        />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0 ">
          <div class="card-body pb-0">
            <div class="body-content-overlay"
                 :class="{ 'show': isEventHandlerSidebarActive }"
                 @click="isEventHandlerSidebarActive = false"
            />
            <button class="btn btn-primary btn-block btn-mobile" @click="isEventHandlerSidebarActive = true">
              +
            </button>
            <full-calendar ref="refCalendar" :options="calendarOptions" class="full-calendar" />
          </div>
        </div>
      </div>

      <div class="body-content-overlay"
           :class="{ 'show': isCalendarOverlaySidebarActive }"
           @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" :event="event"
                              :clear-event-data="clearEventData" @remove-event="removeEvent" @add-event="addEvent"
                              @update-event="updateEvent" />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import CalendarSidebar from './CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'

// eslint-disable-next-line import/no-cycle
import useCalendar from './useCalendar'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
  },
  setup() {
    const {
      refCalendar,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      handleDateSelect,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isCalendarOverlaySidebarActive,
    } = useCalendar()
    return {
      refCalendar,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      handleDateSelect,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isCalendarOverlaySidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import '@core/scss/base/core/colors/palette-variables';

.fc-event,
.fc-event:hover {
  background-color: #3788d8;
}

.fc-event-title,
.fc-event-time {
  color: $white;
}

.fc-scrollgrid-section-header {
  overflow: visible;
  position: sticky;
  top: 62px;
  z-index: 2;
}

.dark-layout {
  .fc-col-header-cell {
    background: $theme-dark-card-bg;
  }
}
</style>

<style lang="scss" scoped>
.btn-mobile {
  display: none;
}

@media (max-width: 767.98px) {
  .btn-mobile {
    position: absolute;
    width: 4rem;
    height: auto;
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
}

</style>
